<template>
  <div class="container">
    <div class="back" @click="$router.go(-1)">
      <i class="iconfont icon-jtback"></i>
      {{ $t('common.back') }}
    </div>
    <q-form @submit="onSubmit">
      <div class="infoCon">
        <div class="infoItem">
          <p>{{ $t('address.ship_to') }}</p>
          <q-select dense v-model="distribution" filled :options="distributionOptions" />
        </div>
        <div class="infoItem" v-show="distribution == $t('address.distributionCN')">
          <p>{{ $t('address.address_info') }}</p>
          <q-select v-model="address" multiple clearable dense max-values="3" options-dense :options="addressOptions"
            @update:model-value="addressOptionsChange" @popup-show="addressOptionsShow" @popup-hide="addressOptionsHide"
            @clear="addressOptionsClear" :rules="[(val) => ruleAddress(val) || $t('address.enter_region')]" />
        </div>
        <div class="infoItem" v-show="distribution == $t('address.distributionAB')">
          <p>{{ $t('address.country') }}</p>
          <q-input dense v-model="params.province" :placeholder="$t('address.enter_country')" :rules="[
            (val) =>
              (val !== null && val !== '') || $t('address.enter_country'),
          ]" />
        </div>
        <div class="infoItem" v-show="distribution == $t('address.distributionAB')">
          <p>{{ $t('address.citys') }}</p>
          <q-input dense v-model="params.city" :placeholder="$t('address.enter_citys')" :rules="[
            (val) =>
              (val !== null && val !== '') || $t('address.enter_citys'),
          ]" />
        </div>
        <div class="infoItem">
          <p>{{ $t('address.detail_address') }}</p>
          <q-input dense v-model="params.address" :placeholder="$t('address.enter_address')" :rules="[
            (val) =>
              (val !== null && val !== '') || $t('address.enter_address'),
          ]" />
        </div>
        <div class="infoItem">
          <p>{{ $t('address.post') }}</p>
          <q-input v-model="params.zcode" dense :placeholder="$t('address.enter_postcode')" />
        </div>
        <div class="infoItem">
          <p>{{ $t('address.name') }}</p>
          <q-input v-model="params.name" maxlength="25" dense :placeholder="$t('address.enter_name')" :rules="[
            (val) => (val !== null && val !== '') || $t('address.enter_name'),
          ]" />
        </div>
        <div class="infoItem">
          <p>{{ $t('address.phone') }}</p>
          <q-input v-model="params.tel" dense maxlength="11" :placeholder="$t('address.enter_phone')" :rules="[
            (val) =>
              (val !== null && val !== '') || $t('address.enter_phone'),
            (val) => phoneRule(val) || $t('address.phone_err'),
          ]" />
        </div>
        <div class="infoItem">
          <p>{{ $t('address.email_address') }}</p>
          <q-input v-model="params.email" dense type="email" maxlength="30" :placeholder="$t('address.enter_email')"
            :rules="[
              (val) =>
                (val !== null && val !== '') || $t('address.enter_email'),
              (val) => emailRule(val) || $t('address.email_err'),
            ]" />
        </div>
      </div>
      <div class="setDefault">
        <q-checkbox v-model="params.defaults" size="xs" :label="$t('address.set_default')" />
      </div>
      <div class="infoBtn">
        <q-btn type="submit" class="full-width" color="primary"
          :label="type == 1 ? $t('address.add') : $t('address.save')" />
      </div>
    </q-form>
  </div>
</template>

<script>
import { phoneRule, emailRule } from '@/common/formRules'
import { address } from '@/api/my'
import addressJson from './address.json'
export default {
  data () {
    return {
      type: 1, // 1是添加，2是修改
      params: {
        name: '',
        acode: '1', // 1 是中国；2 是海外
        tel: '',
        country: '',
        province: '',
        city: '',
        area: '',
        address: '',
        zcode: '',
        defaults: false
      },
      address: this.$t('address.enter_region'),
      distribution: this.$t('address.distributionCN'),
      distributionOptions: [
        this.$t('address.distributionCN'),
        this.$t('address.distributionAB')
      ],
      addressOptions: addressJson
    }
  },
  methods: {
    async onSubmit () {
      if (this.distribution === this.$t('address.distributionAB')) {
        this.params.area = ''
      }
      this.params.country = this.distribution
      this.params.acode = this.distribution === this.$t('address.distributionCN') ? '1' : '2'
      const res = await address({
        type: this.type,
        ...this.params
      })
      if (res.status === 1) {
        this.$q.notify(this.$t('address.change_success'))
        this.$router.go(-1)
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
    },
    addressOptionsChange (e) {
      if (e === null) {
        this.addressOptions = addressJson
        return
      }
      if (e.length === 1) {
        this.addressOptions = e[0].children
      } else if (e.length === 2) {
        this.addressOptions = e[1].children
      } else if (e.length === 3) {
        this.addressOptions = ['']
      }
    },
    addressOptionsShow () {
      this.address = []
      this.addressOptions = addressJson
    },
    addressOptionsHide () {
      if (this.address.length === 0) {
        this.address = this.$t('address.enter_region')
        return
      }
      if (this.address.length === 3) {
        this.params.province = this.address[0].label
        this.params.city = this.address[1].label
        this.params.area = this.address[2].label
      }
    },
    addressOptionsClear () {
      if (this.address === null) {
        this.address = this.$t('address.enter_region')
        this.params.province = ''
        this.params.city = ''
        this.params.area = ''
      }
    },
    ruleAddress (val) {
      // 当前选择海外不做验证
      if (this.distribution === this.$t('address.distributionAB')) {
        return true
      }
      if (val.length === 3) {
        return true
      }
    }
  },
  mounted () {
    if (this.$route.query.info) {
      this.params = JSON.parse(this.$route.query.info)
      this.address = []
      this.address.push(this.params.province)
      this.address.push(this.params.city)
      this.address.push(this.params.area)
      this.type = 2
      this.distribution = this.params.country
    }
  },
  setup () {
    return {
      phoneRule,
      emailRule
    }
  }
}
</script>

<style lang="scss" scoped>
.back {
  max-width: 1500px;
  margin: 0 auto;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
}

.setDefault {
  max-width: 1050px;
  margin: 10px auto;
  padding: 0 25px;
}

.infoCon {
  max-width: 1050px;
  padding: 0 25px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .infoItem {
    width: 45%;
    margin-top: 38px;

    p {
      font-size: 12px;
      margin-bottom: 0px;
    }

    .desc {
      margin-top: 5px;

      span {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}

.infoBtn {
  width: 300px;
  margin: 20px auto;
  margin-top: 78px;
}
</style>
